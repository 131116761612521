
import Swiper, { Navigation } from 'swiper';

class Sliders {
  swiper: Swiper;

  constructor() {
    this.swiper = new Swiper('.products', {
      modules: [Navigation],
      navigation: {
        nextEl: ".products_next",
        prevEl: ".products_prev",
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        768: {
          slidesPerView: 2.5,
          slidesPerGroup: 2,
        },
        1024: {
          slidesPerView: 3.5,
          slidesPerGroup: 2,
        },
        1200: {
          slidesPerView: 4,
          slidesPerGroup: 1,
        }
      }
    });
  }
}

new Sliders;